import React from 'react';
import MainContent from '../components/MainContent';

function Home() {
  return (
    <div>
      <MainContent />
    </div>
  );
}

export default Home;
