import React from 'react';
import ContactContent from '../components/ContactContent';

function Contact() {
  return (
    <div>
      <ContactContent />
    </div>
  );
}

export default Contact;
