import React from 'react';
import AboutContent from '../components/AboutContent';

function About() {
  return (
    <div>
      <AboutContent />
    </div>
  );
}

export default About;
